import { Navigate, useRoutes } from 'react-router-dom';

import { StreamLayout } from './layouts';
import { View1, View2, View3, View4, View5 } from './views/stream';

export default function Router() {

    return useRoutes([
        {
            path: '/:lang',
            element: <StreamLayout />,
            children: [
                {
                    path: '',
                    element: <View1 />,
                },
                {
                    path: 'view',
                    children: [
                        {
                            'path': '',
                            'element': <Navigate to="/" />
                        },
                        {
                            path: '1',
                            element: <View1 />,
                        },
                        {
                            path: '2',
                            element: <View2 />,
                        },
                        {
                            path: '3',
                            element: <View3 />,
                        },
                        {
                            path: '4',
                            element: <View4 />,
                        },
                        {
                            path: '5',
                            element: <View5 />,
                        }
                    ],
                },
            ]
        },
    ]);

}


